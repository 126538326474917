<template>
  <section>
    <div class="bg-image padding p0">
      <div class="level">
        <img class="level-left" src="./../../assets/img/home/logo.png" />
        <div class="level-right header is-size-3">Zaloguj się</div>
      </div>
      <hr />
      <div class="login-box">
        <div class="title columns has-text-centered has-text-white">
          <div class="column is-12 is-size-2">PANEL ADMINISTRATORA</div>
        </div>
        <div class="title columns has-text-centered has-text-white">
          <div class="column is-12 is-size-4">logowanie</div>
        </div>
        <form @submit.prevent="signin">
          <div class="container content is-centered">
            <div class="is-flex is-justify-content-center">
              <figure class="image is-128x128">
                <b-image
                  :src="require('@/assets/img/home/icons/tools.png')"
                ></b-image>
              </figure>
            </div>
            <div class="columns is-vcentered">
              <div class="column is-6">
                <strong>Login</strong>
              </div>
              <div class="column is-6">
                <b-field>
                  <b-input v-model="login" rounded></b-input>
                </b-field>
              </div>
            </div>
            <div class="columns is-vcentered">
              <div class="column is-6">
                <strong>Hasło</strong>
              </div>
              <div class="column is-6">
                <b-field>
                  <b-input v-model="password" rounded type="password"></b-input>
                </b-field>
              </div>
            </div>
            <div class="columns is-centered">
              <b-button
                type="is-primary"
                rounded
                size="is-medium"
                class="button is-centered"
                @click="signin()"
                >Zaloguj się</b-button
              >
            </div>
            <b-message type="is-danger" class="center" v-if="status"
              >Błąd logowania {{ content }}</b-message
            >
          </div>
        </form>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "AdminLogin",
  data() {
    return {
      login: "",
      password: "",
      status: false,
      content: "",
      isLoading: false,
      isFullPage: true,
    };
  },
  methods: {
    signin: function () {
      this.status = false;
      this.content = "";
      //console.log("123");
      if (
        this.login != "administrator" ||
        this.login == undefined ||
        this.password == undefined
      ) {
        this.status = true;
        this.content = "niepoprawne dane logowania";
        return;
      }
      let payload = {
        username: this.login,
        password: this.password,
      };
      //vuex
      this.$store
        .dispatch("auth/login", payload)
        .then((response) => {
          //console.log("res", response);
          if (response.status != 200) {
            this.response = {
              status: true,
              content: "niepoprawne dane logowania",
            };
          } else {
            this.isLoading = false;
            this.$router.push({ name: "AdminPanel" });
          }
        })
        .catch(() => {
          //console.log("error");
          (this.status = true),
            (this.content = "niepoprawne dane logowania"),
            (this.isLoading = false);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.login-box {
  position: relative;
  top: 5rem;
}

figure {
  top: 0.5rem;
}
.bg-image {
  background-image: url("./../../assets/img/background/bck2.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-color: #999;
  padding: 0;
  padding-top: 1rem;
  min-height: 50vw;

  hr {
    width: 90vw;
    height: 0.5rem;
    position: relative;
    left: 5vw;
  }

  .content {
    width: 30vw;
    min-height: 35vh;
    background-color: #ffffffc9;
    border-radius: 15px;
  }

  .header {
    position: relative;
    color: $primary;
    font-weight: 400;
    right: 1rem;
  }

  .container {
    .columns {
      margin-left: 1rem;
      margin-right: 1rem;
    }
  }
}
</style>