<template>
  <section>
    <admin-login> </admin-login>
    <page-footer></page-footer>
  </section>
</template>

<script>
import footer from "./../../components/common/Footer";
import login from "./../../components/admin/Login";
export default {
  name: "AdminLogin",
  components: {
    "admin-login": login,
    "page-footer": footer,
  },
};
</script>

<style lang="scss" scoped>
</style>